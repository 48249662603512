import MuiAppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import { IO } from 'fp-ts/lib/IO';
import React from 'react';
import { useLocation } from 'react-router';
import SearchBar from '../../../components/SearchBar';
import UserInfo from '../../../components/UserInfo';

import SpacLogo from './SpacLogo';

export interface IAppBarProps {
    toggleDrawer: IO<void>,
}

const AppBar: React.FC<IAppBarProps> = ({ toggleDrawer }) => {
  const location = useLocation();
  const showSearchBar = !location.pathname.includes('/upload');

  return (
    <MuiAppBar position='static' color='inherit'>
      <Toolbar>
        <Hidden smUp>
          <IconButton onClick={toggleDrawer}><MenuIcon /></IconButton>
        </Hidden>
        <SpacLogo />
        {showSearchBar && <SearchBar />}
        <Box flexGrow={1} />
        <UserInfo />
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
