import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import AppBootstrap from './components/AppBootstrap';
import { store } from './store/store';
import theme from './theme/schindler';

ReactDOM.render(
  <Provider store={store}>
    <CssBaseline />
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <AppBootstrap />
      </BrowserRouter>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('root')
);
