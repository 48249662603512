import { Box, Divider, Grid, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import React, { ReactChild, useMemo } from 'react';
import { SparePart } from '../../store/models/SpareParts';
import { hasValue } from '../../utils/nullishHelpers';

interface TitleValue {
  title: string
  value?: ReactChild
  fullWidth?: boolean
}

export interface TitleValueSupplier extends Omit<TitleValue, 'value'>{
  value: (detail: SparePart) => TitleValue['value']
}

interface InfoSectionProps {
  information: Array<TitleValueSupplier>
  materialDetail: SparePart
  sectionTitle?: ReactChild
  SectionIconTitle?: React.ElementType
}

const StyledBox = styled(Box)(
  ({ theme }) => ({
    color: theme.palette.info.main,
    fontVariant: 'body1'
  })
);

const LabelValue: React.FC<TitleValue> = ({ title, value, fullWidth }) => (value ? (
  <Grid item component={Box} pb={2} xs={fullWidth ? 12 : 6} sm={fullWidth ? 12 : 4}>
    <Typography color='textSecondary' variant='caption'>{title}</Typography>
    <Typography variant='body1'>{value || '-'}</Typography>
  </Grid>
) : null);

const DetailField: React.FC<InfoSectionProps> = ({ information, materialDetail, sectionTitle, SectionIconTitle }) => {
  const infoNoEmptyValue = useMemo(
    () => information
      .map(({ value, ...info }) => ({ value: value(materialDetail), ...info }))
      .filter(info => hasValue(info.value)), [information, materialDetail]
  );
  return (
    <>
      {
        sectionTitle && (
          <>
            <StyledBox px={2} pt={1} mb={2} display='inline-flex' alignItems='flex-end'>
              { SectionIconTitle && <Box pr={2} display='inline-flex'><SectionIconTitle /></Box> }
              {sectionTitle}
            </StyledBox>
            <Divider />
          </>
        )
      }
      <Box px={2} pt={1} mt={2}>
        <Grid container>
          {
            infoNoEmptyValue
              ? infoNoEmptyValue
                .map(({ title, value, fullWidth }) => (
                  <LabelValue key={title} title={title} value={value} fullWidth={fullWidth} />
                )) : sectionTitle && <Typography color='textSecondary' variant='caption'>Data not available</Typography>
          }
        </Grid>
      </Box>
    </>
  );
};

export default DetailField;
