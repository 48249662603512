import { Box, Drawer, styled } from '@material-ui/core';
import { BoxProps } from '@material-ui/core/Box/Box';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import LocalOfferOutlinedIcon from '@material-ui/icons/LocalOfferOutlined';
import { IO } from 'fp-ts/lib/IO';
import React, { useEffect, useMemo } from 'react';
import { RouteComponentProps, useLocation } from 'react-router';
import ErrorMessage from '../../components/ErrorMessage';
import TablePlaceholder from '../../components/TablePlaceholder';
import { SparePart } from '../../store/models/SpareParts';
import State, { Status } from '../../store/models/State';
import DetailField from './DetailFields';
import DetailHeader, { HeadersProps } from './DetailHeader';
import DetailImage from './DetailImage';
import { firstSection, secondSection, thirdSection } from './DetailInfoSections';
import DiscountsTable from './DiscountsTable';
import PriceTable from './PriceTable';

export interface IDetailDispatchProps {
  getSparePart: (id: string) => void
  resetSparePart: IO<void>
}

export interface IDetailStateProps {
  detail: State<SparePart, string>
  isSupplyChainUser: boolean
}

export interface IDetailProps {
  id: string
}

const StyledBox = styled(Box)(
  ({ theme }) => ({
    width: '50em',
    [theme.breakpoints.down('sm')] : {
      width: '70vw'
    }
  })
);

const DetailDrawer:
  React.ComponentType<BoxProps & HeadersProps> = ({
    children,
    onClose: backToList,
    headerName,
    bgcolor = 'white'
  }) => {
    const location = useLocation();
    return (
      <Drawer
        open={location.pathname.startsWith('/detail')}
        anchor='right'
        onClose={backToList}
        data-testid='drawer-detail'
      >
        <StyledBox display='flex' flexDirection='column' flexGrow='1' bgcolor={bgcolor}>
          <DetailHeader headerName={headerName} onClose={backToList} />
          {children}
        </StyledBox>
      </Drawer>
    );
  };

const Detail:
  React.FC<IDetailDispatchProps & IDetailStateProps & RouteComponentProps<IDetailProps>> = ({
    getSparePart,
    resetSparePart,
    detail,
    history,
    location,
    isSupplyChainUser
  }) => {
    const detailId = useMemo(() => location.pathname.match(/detail\/(.*)/)?.[1] ?? '', [location.pathname]);
    useEffect(() => { if (detailId) { getSparePart(detailId) } }, [getSparePart, detailId]);
    const backToList = () => {
      history.push({
        pathname: '/',
        search: history.location.search,
      });
      resetSparePart();
    };

    switch (detail.status) {
      case (Status.Success):
        return (
          <DetailDrawer
            bgcolor='grey.50'
            onClose={backToList}
            headerName={detailId}
          >
            <Box display='flex' flexDirection='column' flexGrow='1'>
              <Box component='section' p={3} bgcolor='white'>
                <Box display='flex' justifyContent='center' alignItems='center'>
                  <DetailImage id={detailId} maxHeight='10vh' />
                </Box>
              </Box>
              <Box component='section'>
                <DetailField information={firstSection} materialDetail={detail.data} />
                <DetailField
                  information={secondSection}
                  materialDetail={detail.data}
                  sectionTitle='Technical specs'
                  SectionIconTitle={AspectRatioOutlinedIcon}
                />
                <DetailField
                  information={thirdSection(isSupplyChainUser)}
                  materialDetail={detail.data}
                  sectionTitle='Availability details'
                  SectionIconTitle={LocalOfferOutlinedIcon}
                />
                {detail.data.price_stock_1 && <PriceTable sparePart={detail.data} />}
                <DiscountsTable discounts={detail.data.discounts} isSupplyChainUser={isSupplyChainUser} />
              </Box>
            </Box>
          </DetailDrawer>
        );
      case (Status.Loading):
      case (Status.NotAsked):
        return (
          <DetailDrawer
            onClose={backToList}
            headerName={detailId}
          >
            <TablePlaceholder />
          </DetailDrawer>
        );
      case (Status.Error):
        return (
          <DetailDrawer
            onClose={backToList}
            headerName={detailId}
          >
            <ErrorMessage title='Error' message='There was an error retrieving the item' icon='error' />
          </DetailDrawer>
        );
    }
  };

export default Detail;
