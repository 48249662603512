import { ListItemIcon } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';
import { Link } from 'react-router-dom';

interface IMenuItemProps {
    target: string
    external: boolean
    title: string
    icon: JSX.Element
    selected: boolean
}

const MenuItem: React.FC<IMenuItemProps> = ({ icon, title, target, selected, external }) => (
  <ListItem
    button
    component={external ? 'a' : Link}
    to={external ? undefined : target}
    selected={selected}
    href={external ? target : undefined}
    target={external ? '_blank' : undefined}
    rel='norel noopener'
  >
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <ListItemText primary={title} />
  </ListItem>
);

export default MenuItem;
