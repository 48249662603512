import { connect } from 'react-redux';
import { setSearchQuery } from '../../store/reducers/search';
import { RootState } from '../../store/store';
import SearchBar, { SearchBarStateProps, SearchBarDispatchProps } from './SearchBar';

const mapStateToProps = (state: RootState) => ({
  query: state.search.query
});

const mapDispatchToProps = ({
  setSearchQuery
});

export default connect<SearchBarStateProps, SearchBarDispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(SearchBar);
