import { InputBase, styled } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import SearchIcon from '@material-ui/icons/Search';
import React, { useCallback, useRef, useEffect } from 'react';

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: theme.typography.subtitle2.color,
  fontSize: theme.typography.subtitle2.fontSize,
  fontWeight: theme.typography.subtitle2.fontWeight,
}));

const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  marginRight: theme.spacing(1)
}));

export interface SearchBarStateProps {
    query: string
}

export interface SearchBarDispatchProps {
    setSearchQuery: (newQuery: string) => void
}

const SearchBar: React.FC<SearchBarStateProps & SearchBarDispatchProps> = (
  { query, setSearchQuery }
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = useCallback<React.ChangeEventHandler<HTMLInputElement>>(event => {
    setSearchQuery(event.target.value);
  }, [setSearchQuery]);

  const handleInput = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    inputRef.current?.focus?.();
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleInput, true);
    return () => document.removeEventListener('keydown', handleInput);
  }, [handleInput]);

  return (
    <Box mx={1} pl={2} display='flex' borderLeft={1} borderColor='grey.400'>
      <StyledInputBase
        startAdornment={<StyledSearchIcon color='disabled' fontSize='large' />}
        placeholder='Type to search'
        value={query}
        onChange={handleChange}
        inputRef={inputRef}
        autoFocus
        inputProps={{
          'data-testid': 'header-search-input'
        }}
      />
    </Box>
  );
};

export default SearchBar;
