import { Dispatch } from '@reduxjs/toolkit';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { selectCategoriesFromIds } from '../../store/reducers/categories';
import { setSearchQuery } from '../../store/reducers/search';
import { exportSpareParts, findSpareParts, QueryOptions } from '../../store/reducers/spareparts';
import { RootState } from '../../store/store';
import Search, { ISearchDispatchProps, ISearchStateProps } from './Search';

const mapStateToProps = (state: RootState) => ({
  search: state.spareParts.search,
  query: state.search.query,
  exportStatus: state.spareParts.export.status,
  selectedCategories: state.categories.selected.map(x => x.subCategory).join(','),
  categoriesStatus: state.categories.categories.status
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  findSpareParts: (options: QueryOptions) => findSpareParts(options)(dispatch),
  exportSpareParts: (
    query: string,
    selectedCategories: string
  ) => exportSpareParts(query, selectedCategories)(dispatch),
  setSearchQuery: (query: string) => dispatch(setSearchQuery(query)),
  selectCategoriesFromIds: (categories: string) => dispatch(selectCategoriesFromIds(categories.split(',')))
});

export default connect<ISearchStateProps, ISearchDispatchProps, RouteComponentProps, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Search));
