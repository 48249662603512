import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/core/styles';
import React from 'react';

import logo from './logo.png';

const StyledBox = styled(Box)(
  ({ theme }) => ({ height: theme.mixins.toolbar.height })
);

const SidebarHeader = () => (
  <StyledBox
    p={1}
    display='inline-flex'
    justifyContent='center'
    boxShadow={2}
    bgcolor='drawerHeaderBg'
  >
    <img
      src={logo}
      alt='schindler'
    />
  </StyledBox>
);

export default SidebarHeader;
