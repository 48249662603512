import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { identity } from 'fp-ts/lib/function';
import { IO } from 'fp-ts/lib/IO';
import { pipe } from 'fp-ts/lib/pipeable';
import * as T from 'fp-ts/lib/Task';
import * as TE from 'fp-ts/lib/TaskEither';
import Keycloak from 'keycloak-js';
import merge from 'lodash.merge';
import getError from '../../utils/getError';

export interface KeycloakProfileWithRoles extends Keycloak.KeycloakProfile {
  roles: string[]
}

class KeycloakRepository {
    private kc: Keycloak.KeycloakInstance<'native'>;

    private kcClient = process.env.REACT_APP_KC_CLIENT || 'marketplaceapp';

    constructor() {
      this.kc = Keycloak<'native'>('/keycloak.json');
    }

    init = () => {
      this.clearAxiosInterceptors();
      return TE.tryCatch(
        () => this.kc
          .init({
            onLoad: 'check-sso',
            promiseType: 'native' })
          .then(() => {
            if (!this.kc.authenticated) { return this.kc.login() }
          }),
        getError
      );
    };

    getProfile = () => TE.tryCatch<string, KeycloakProfileWithRoles>(
      () => this.kc.loadUserProfile().then(data => ({
        ...data,
        // eslint-disable-next-line camelcase
        roles: this.kc.tokenParsed?.resource_access?.[this.kcClient]?.roles ?? []
      })),
      getError
    );

    logout = () => {
      this.kc.logout();
      this.clearAxiosInterceptors();
    };

    private refreshToken = TE.tryCatch<string, boolean>(
      (minValidity = 5) => this.kc.updateToken(minValidity),
      getError
    );

    private setAxiosAuthorization = (config: AxiosRequestConfig, token: string) => merge(
      config,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    private axiosInterceptor = (config: AxiosRequestConfig) => pipe(
      this.refreshToken,
      TE.map<boolean, AxiosRequestConfig>(
        () => {
          if (this.kc.token) { return this.setAxiosAuthorization(config, this.kc.token) }
          throw Error('No token found');
        }
      ),
      TE.fold<string, AxiosRequestConfig, AxiosRequestConfig>(
        err => {
          throw err;
        },
        x => T.of(x)
      )
    )();

    clearAxiosInterceptors: IO<void> = () => {
      /*
        Axios has an eject method for interceptors, but it does not really
        remove the previously defined interceptor: it just replaces it with
        a null function, so the handlers array gets polluted and this
        interferes with testing and makes it impossible to have predictable
        behaviour with parallel execution, so we're forcibly removing any
        leftover
       */
      (axios.interceptors.request as any).handlers = [];
      (axios.interceptors.response as any).handlers = [];
    };

    bootstrapAxios = (onExpired: IO<void>): IO<void> => () => {
      this.kc.onAuthRefreshError = onExpired;
      axios.interceptors.request.use(this.axiosInterceptor);

      axios.interceptors.response.use(
        identity,
        (error: AxiosError) => {
          if (error.response && error.response.status === 401) {
            console.warn('Session expired or connection failed');
            onExpired();
          }
          console.error('Axios::ResponseInterceptor ', error);
          return Promise.reject(error);
        }
      );
    };
}

export default KeycloakRepository;
