import { AxiosResponse } from 'axios';

const downloadBlob = (response: AxiosResponse) => {
  const disposition = response.headers['content-disposition'];
  const filename = decodeURI(disposition.match(/filename="(.*)"/)[1]);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};

export default downloadBlob;
