export const filterNullProperties = (obj: object): object => (Array.isArray(obj)
  ? obj.map(x => ((typeof x === 'object' && !!x) ? filterNullProperties(x) : x))
  : Object.fromEntries(
    Object.entries(obj)
      .filter(([, v]) => v != null)
      .map(([k, v]) => (typeof v === 'object' ? [k, filterNullProperties(v)] : [k, v]))
  ));

export type Maybe<T> = T | undefined;

export const hasValue = <T>(x: Maybe<T>): x is T => x !== undefined;
