export default [
  {
    headerName: 'Id',
    field: 'externalId',
    flex: 1
  },
  {
    headerName: 'Image',
    field: 'externalId',
    flex: 2,
    cellRenderer: 'imageRenderer'
  },
  {
    headerName: 'Description',
    field: 'description',
    flex: 6
  },
  {
    headerName: 'Dimensions',
    field: 'dimension',
    flex: 2
  },
  {
    headerName: 'Weight',
    field: 'weight',
    flex: 2,
  },
];
