import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';

interface SearchQueryState {
    query: string,
}

const initialState: SearchQueryState = {
  query: '',
};

export const slice = createSlice<SearchQueryState, SliceCaseReducers<SearchQueryState>>({
  name: 'search',
  initialState,
  reducers: {
    setSearchQuery: (_, action: PayloadAction<string>) => ({
      query: action.payload }),
  }
});

export const { setSearchQuery } = slice.actions;

export default slice.reducer;
