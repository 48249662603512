import axios from 'axios';
import { pipe } from 'fp-ts/lib/pipeable';
import * as TE from 'fp-ts/lib/TaskEither';

import getError from '../../utils/getError';
import { filterNullProperties } from '../../utils/nullishHelpers';
import { UploadInfoCodec } from '../models/UploadInfo';

class UploadRepository {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    upload = (file: File, force: boolean) => {
      const data = new FormData();
      data.set('file', file);

      return pipe(
        TE.tryCatch<any, unknown>(
          () => axios.put(
            `/api/loader/upload/excel?removeExisting=${force}`, data,
            { headers: {
              'Content-Type': 'multipart/form-data'
            } }
          ),
          getError
        ),
        TE.mapLeft(err => getError(err))
      );
    };

    checkStatus = pipe(
      TE.tryCatch<any, Record<any, Record<string, any>>>(
        () => axios.get(
          '/api/loader/progress/import'
        ),
        getError
      ),
      TE.map(x => filterNullProperties(x.data)),
      TE.chain(x => TE.fromEither(UploadInfoCodec.decode(x))),
      TE.mapLeft(err => getError(err))
    );
}

export default UploadRepository;
