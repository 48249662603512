const getError = (rawError: unknown) => {
  const message = (rawError instanceof Error
    ? rawError.message
    : String(rawError));
  console.error(message);

  return message;
};

export default getError;
