import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import User from '../../store/models/User';

interface IUserDescriptionProps {
    profile: User
}

const UserDescription: React.FC<IUserDescriptionProps> = ({ profile }) => (
  <Box px={2}>
    <Typography variant='subtitle2'>
      {`${profile.firstName} ${profile.lastName}`}
    </Typography>
  </Box>
);

export default UserDescription;
