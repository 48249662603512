import { ListItemIcon } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import React from 'react';

interface IMenuItemProps {
    target: string
    title: string
    icon: JSX.Element
}

const LinkMenuItem: React.FC<IMenuItemProps> = ({ icon, title, target }) => (
  <ListItem
    button
    component='a'
    href={target}
    target='_blank'
    rel='norel noopener'
  >
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <ListItemText primary={title} />
  </ListItem>
);

export default LinkMenuItem;
