import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

const TablePlaceholder: React.FC = () => (
  // Magic number, but ag-grid wants pixel height for the header
  <Box
    position='absolute'
    top={32}
    width='100%'
    display='flex'
    height='calc(100% - 32px)'
    bgcolor='rgba(255,255,255,0.8)'
    justifyContent='center'
    alignItems='center'
    data-testid='table-placeholder'
  >
    <CircularProgress />
  </Box>
);

export default TablePlaceholder;
