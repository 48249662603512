import * as t from 'io-ts';

export const UploadInfoCodec = t.partial({
  importId: t.string,
  start: t.string,
  stop: t.string,
  error: t.array(t.string),
  sheets: t.record(
    t.string,
    t.type({
      parsedRecords: t.number,
      imported:t.number
    })
  )
});

export type UploadInfo = t.TypeOf<typeof UploadInfoCodec>;
