import * as t from 'io-ts';

const CategoryCodec = t.type({
  category: t.string,
  subcategory: t.string
});

export const CategoriesCodec = t.array(CategoryCodec);

export type Category = t.TypeOf<typeof CategoryCodec>;
export type Categories = t.TypeOf<typeof CategoriesCodec>;
