import { styled, Box } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';
import React, { useState } from 'react';

const StyledImg = styled('img')({
  objectFit: 'contain',
  height: '100%'
});

const DetailImage: React.FC<{id: string, maxHeight: string | number}> = ({ id, maxHeight }) => {
  const [showPlaceholder, setShowPlaceholder] = useState<boolean>(false);
  return showPlaceholder
    ? <ImageIcon fontSize='large' color='disabled' />
    : (
      <Box height={maxHeight} p={1}>
        <StyledImg
          src={`/images/${id.padStart(8, '0')}.JPG`}
          alt=''
          role='presentation'
          onError={() => setShowPlaceholder(true)}
        />
      </Box>
    );
};

export default DetailImage;
