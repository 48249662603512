import * as t from 'io-ts';

const DiscountRequiredCodec = t.type({
  partyId: t.number,
  partyName: t.string,
  customerName: t.string,
  incoterm: t.string,
});

const DiscountOptionalCodec = t.partial({
  dapFee: t.number,
  priceWithDapFee: t.number,
  dapLeadTimeExtra: t.number,
  leadTimeFromContracts: t.number,
  computedLeadTime: t.number,
  priceStock1Size: t.number,
  priceStock2Size: t.number,
  priceStock3Size: t.number,
  priceStock1Discount: t.number,
  priceStock2Discount: t.number,
  priceStock3Discount: t.number,
  priceStock1: t.number,
  priceStock2: t.number,
  priceStock3: t.number,
});

const DiscountCodec = t.intersection([
  DiscountRequiredCodec,
  DiscountOptionalCodec
]);

const SparePartRequiredCodec = t.type({
  externalId: t.string,
});

const SparePartOptionalCodec = t.partial({
  description: t.string,
  dimension: t.string,
  weight: t.number,
  leadTime: t.number,
  fastOrder: t.boolean,
  configurable: t.boolean,
  stockQuantity: t.number,
  minQuantity: t.number,
  minQuantityUom: t.string,
  hsCode: t.string,
  version: t.string,
  incoterm: t.string,
  cwhMaterial: t.string,
  status: t.string,
  price: t.number,
  currency: t.string,
  lotSize: t.number,
  lotSizeUom: t.string,
  category: t.string,
  subcategory: t.string,
  supplier: t.string,
  baseDiscount: t.number,
  price_stock_size_1: t.number,
  price_stock_size_2: t.number,
  price_stock_size_3: t.number,
  price_stock_1: t.number,
  price_stock_2: t.number,
  price_stock_3: t.number,
  discounts: t.array(DiscountCodec),
});

export const SparePartCodec = t.intersection([
  SparePartRequiredCodec,
  SparePartOptionalCodec
]);

export type SparePart = t.TypeOf<typeof SparePartCodec>;
export type Discount = t.TypeOf<typeof DiscountCodec>;
