import { Lazy } from 'fp-ts/lib/function';
import { IO } from 'fp-ts/lib/IO';
import { useRef, useEffect } from 'react';

export type Cancelable = {
  cancel: () => void;
};

/*
    Returns a `useEffect` that also cancels the execution of the previous `Cancelable`
    instance, if present: this is used to stop debounced functions from being processed
 */
export default function useCancelableEffect(effect: Lazy<IO<void> & Cancelable>, dependencies: any[]) {
  // Store the async action currently being processed, so that it can be cancelled
  const currentThunk = useRef<null |(IO<void> & Cancelable)>(null);

  useEffect(() => {
    if (currentThunk.current !== null) {
      currentThunk.current.cancel();
    }
    currentThunk.current = effect();
    (currentThunk.current as IO<void>)();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
}
