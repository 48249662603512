import Avatar from '@material-ui/core/Avatar';
import React from 'react';
import User from '../../store/models/User';

interface IUserAvatarProps {
    profile: User
}

const UserAvatar: React.FC<IUserAvatarProps> = ({ profile }) => (
  <Avatar>
    {`${profile.firstName[0]}${profile.lastName[0]}`.toUpperCase()}
  </Avatar>
);

export default UserAvatar;
