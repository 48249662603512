import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { IO } from 'fp-ts/lib/IO';
import React, { useCallback } from 'react';

interface IUserMenuProps {
    anchorEl?: HTMLButtonElement,
    onClose: IO<void>
    logout: IO<void>
}

const UserMenu: React.FC<IUserMenuProps> = ({ anchorEl, onClose, logout }) => {
  const handleLogoutClick = useCallback(() => {
    logout();
    onClose();
  }, [onClose, logout]);

  return (
    <Menu
      tabIndex={-1}
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      getContentAnchorEl={null}
    >
      <MenuItem
        onClick={handleLogoutClick}
      >
        <Typography variant='body2'>Logout</Typography>
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
