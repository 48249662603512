import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { IO } from 'fp-ts/lib/IO';
import React from 'react';

interface ISessionExpiredModalProps {
    logout: IO<void>
}

const SessionExpiredModal: React.FC<ISessionExpiredModalProps> = ({ logout }) => (
  <Dialog open>
    <DialogContent>
      Your session has expired. To continue using the application, login again.
    </DialogContent>
    <DialogActions>
      <Button color='primary' onClick={logout}>Go to login</Button>
    </DialogActions>
  </Dialog>
);

export default SessionExpiredModal;
