import { connect } from 'react-redux';

import { deselectCategories, getCategories, selectCategories } from '../../store/reducers/categories';
import { RootState } from '../../store/store';
import CategoryFilters, { CategoryFiltersDispatchProps, CategoryFiltersStateProps } from './CategoryFilters';

const mapStateToProps = (state: RootState) => ({
  categories: state.categories.categories,
  selected: state.categories.selected
});

const mapDispatchToProps = ({
  getCategories,
  onSelectCategories: selectCategories,
  onDeselectCategories: deselectCategories,
});

export default connect<CategoryFiltersStateProps, CategoryFiltersDispatchProps, {}, RootState>(
  mapStateToProps, mapDispatchToProps
)(CategoryFilters);
