import { Action, configureStore, ThunkAction } from '@reduxjs/toolkit';

import categoriesReducer from './reducers/categories';
import searchReducer from './reducers/search';
import sparePartReducer from './reducers/spareparts';
import uploadReducer from './reducers/upload';
import userReducer from './reducers/user';

export const store = configureStore({
  reducer: {
    user: userReducer,
    spareParts: sparePartReducer,
    categories: categoriesReducer,
    search: searchReducer,
    upload: uploadReducer
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;
