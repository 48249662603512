import {
  Box,
  ButtonBase,
  Checkbox,
  Grid,
  styled,
  Typography
} from '@material-ui/core';
import React, { useCallback, useState } from 'react';

import { Category } from '../../store/models/Categories';
import { SelectedCategoryInfo } from '../../store/reducers/categories';
import CategoryChip from './CategoryChip';
import categoryToSelectionData from './utils';

const MAX_SHOWN_CATEGORIES = 10;

const UppercaseTypography = styled(Typography)({
  textTransform: 'uppercase'
});

const UnderlinedTypography = styled(Typography)({
  textDecoration: 'underline'
});

interface CategorySectionProps {
    subcategories: Category[]
    selected: SelectedCategoryInfo[]
    onSelectCategories: (categories: SelectedCategoryInfo[]) => void
    onDeselectCategories: (categories: SelectedCategoryInfo[]) => void
}

const CategorySection: React.FC<CategorySectionProps> = ({
  subcategories,
  selected,
  onSelectCategories,
  onDeselectCategories
}) => {
  const showMore = subcategories.length > MAX_SHOWN_CATEGORIES;
  const [expanded, setExpanded] = useState<boolean>(false);
  const handleCheckboxClick = useCallback(() => {
    if (selected.length === subcategories.length) {
      onDeselectCategories(subcategories.map(categoryToSelectionData));
    } else {
      onSelectCategories(subcategories.map(categoryToSelectionData));
    }
  }, [subcategories, onSelectCategories, onDeselectCategories, selected]);

  const onSelectCategory = useCallback(subcategory => () => onSelectCategories(
    [categoryToSelectionData(subcategory)]
  ), [onSelectCategories]);
  const onDeselectCategory = useCallback(subcategory => () => onDeselectCategories(
    [categoryToSelectionData(subcategory)]
  ), [onDeselectCategories]);

  return (
    <Grid container data-testid='category-section'>
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <Box px={2} py={1} height='100%' display='flex' alignItems='center'>
            <UppercaseTypography variant='caption'>Category</UppercaseTypography>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box px={2} py={1} bgcolor='grey.100' display='flex' flexDirection='row' alignItems='center'>
            <UppercaseTypography variant='subtitle2' data-testid='category-section-title'>
              {subcategories[0].category}
              {' '}
              (
              {selected.length}
              /
              {subcategories.length}
              )
            </UppercaseTypography>
            <Box display='flex' flexWrap='no-wrap' alignItems='center'>
              <Checkbox
                color='primary'
                onClick={handleCheckboxClick}
                checked={!!selected.length}
                indeterminate={!!selected.length && selected.length < subcategories.length}
                inputProps={{
                  // @ts-ignore
                  'data-testid': 'category-section-checkbox'
                }}
              />
              <Typography variant='caption' data-testid='category-section-checkbox-label'>
                {selected.length !== subcategories.length ? 'Select' : 'Deselect'}
                {' '}
                all
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <Box px={2} py={1} height='100%' display='flex' alignItems='flex-start'>
            <UppercaseTypography variant='caption'>Subcategories</UppercaseTypography>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box px={2} height='100%' display='flex' bgcolor='grey.100' flexWrap='wrap'>
            {subcategories.slice(0, showMore && !expanded ? 10 : undefined).map(subcategory => (
              <CategoryChip
                name={subcategory.subcategory}
                key={subcategory.subcategory}
                // Suboptimal: O(n^2) - could be improved if performance is affected
                isSelected={!!selected.find(item => item.subCategory === subcategory.subcategory)}
                onSelect={onSelectCategory(subcategory)}
                onDeselect={onDeselectCategory(subcategory)}
              />
            ))}
            {showMore && (
            <Box marginBottom={1} width='100%'>
              <ButtonBase onClick={() => setExpanded(current => !current)}>
                <UnderlinedTypography variant='body2' data-testid='toggle-extra-items'>
                  Show
                  {' '}
                  {expanded ? 'less' : `all ${subcategories.length} subcategories` }
                </UnderlinedTypography>
              </ButtonBase>
            </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CategorySection;
