import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';

import { Box, Card, CardContent, CardHeader, styled, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { GridApi, RowClickedEvent } from 'ag-grid-community';
import { SortChangedEvent, GridReadyEvent } from 'ag-grid-community/dist/lib/events';
import { AgGridReact } from 'ag-grid-react';
import { IO } from 'fp-ts/lib/IO';
import { fromNullable, toNullable } from 'fp-ts/lib/Option';
import qs from 'qs';
import React, { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router';

import CategoryFilters from '../../components/CategoryFilters';
import ErrorMessage from '../../components/ErrorMessage';
import SearchActions from '../../components/SearchActions';
import TablePagination from '../../components/TablePagination';
import TablePlaceholder from '../../components/TablePlaceholder';
import Page from '../../store/models/Page';
import { SparePart } from '../../store/models/SpareParts';
import State, { Status } from '../../store/models/State';
import { QueryOptions } from '../../store/reducers/spareparts';
import useCancelableEffect, { Cancelable } from '../../utils/useCancelableEffect';
import useLazy from '../../utils/useLazy';
import Detail from '../Detail';
import DetailImage from '../Detail/DetailImage';
import columns from './columns';

const ROW_HEIGHT = 96;

const StyledBox = styled(Box)(({ theme }) => ({
  '& .ag-header-cell-text': {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight,
    textTransform: 'uppercase'
  }
}));

const useAgGridStyles = makeStyles((theme: Theme) => ({
  row: {
    fontFamily: 'IBM Plex Sans, Segoe UI, Roboto',
    '&:nth-of-type(odd)': {
      background: `${theme.palette.grey['50']} !important`
    },
    '&:hover': {
      background: `${theme.palette.grey['100']} !important`
    },
    '& .ag-cell': {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 'unset'
    }
  }
}));

export interface ISearchStateProps {
  search: State<Page<SparePart>, string>
  query: string,
  exportStatus: Status
  selectedCategories: string,
  categoriesStatus: Status,
}

export interface ISearchDispatchProps {
  findSpareParts: (options: QueryOptions) => IO<void> & Cancelable
  exportSpareParts: (query: string, selectedCategories: string) => void
  setSearchQuery: (query: string) => void
  selectCategoriesFromIds: (categories: string) => void
}

const StyledCard = styled(Card)({
  overflow: 'auto',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const StyledCardContent = styled(CardContent)({
  overflow: 'auto',
  display: 'flex',
  flex: 1
});

const Search: React.FC<ISearchStateProps & ISearchDispatchProps & RouteComponentProps> = ({
  search,
  setSearchQuery,
  findSpareParts,
  exportSpareParts,
  exportStatus,
  history,
  query,
  selectedCategories,
  selectCategoriesFromIds,
  categoriesStatus
}) => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [sortDirection, setSortDirection] = useState<QueryOptions['sortDirection']>('ASC');
  const [sortOrder, setSortOrder] = useState<QueryOptions['sortOrder']>('externalId');

  const lazySetPage = useLazy(setPage);

  const agGridRef = useRef<AgGridReact>(null);
  const classes = useAgGridStyles();

  const onAgGridReady = useCallback((params: GridReadyEvent) => {
    if (search.status === Status.Loading || search.status === Status.Error) { params.api.hideOverlay() }
  }, [search]);

  const onAgGridSort = useCallback((params: SortChangedEvent) => {
    const sortModel = params.api.getSortModel();
    if (sortModel.length) {
      setSortDirection(sortModel[0].sort === 'asc' ? 'ASC' : 'DESC');
      setSortOrder(sortModel[0].colId as QueryOptions['sortOrder']);
    } else {
      setSortDirection('ASC');
      setSortOrder('externalId');
    }
  }, []);

  useEffect(() => {
    if (categoriesStatus === Status.Success) {
      const {
        query: qQuery,
        page: qPage,
        pageSize: qPageSize,
        sortDirection: qSortDirection,
        sortOrder: qSortOrder,
        categories: qCategories
      } = qs.parse(history.location.search.slice(1));
      setSortDirection(qSortDirection || 'ASC');
      setSortOrder(qSortOrder || 'externalId');
      setPageSize(Number(qPageSize) || 10);
      setPage(Number(qPage) || 0);
      setSearchQuery(qQuery || '');
      selectCategoriesFromIds(qCategories || '');
    }
    // We don't want to depend on history: this only has to run on the first load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSearchQuery, categoriesStatus]);

  useCancelableEffect(() => {
    if (categoriesStatus === Status.Success) {
      const currentPath = history.location.pathname;
      const prefix = currentPath && currentPath.includes('detail') ? currentPath : '/';
      history.push(
        // eslint-disable-next-line max-len
        `${prefix}?query=${query}&page=${page}&pageSize=${pageSize}&sortDirection=${sortDirection}&sortOrder=${sortOrder}&categories=${selectedCategories}`
      );
    }
    return findSpareParts({ query, page, pageSize, sortDirection, sortOrder, categories: selectedCategories });
  },
  [query, page, pageSize, sortOrder, sortDirection, findSpareParts, selectedCategories]);

  const viewClaimDetail = ({ data }: RowClickedEvent) => {
    const { location } = history;
    const url = `/detail/${(data as SparePart).externalId}`;

    if (history && history.push) {
      history.push({ pathname: url, search: location.search });
    }
  };

  const pageData = useMemo(() => {
    if (search.status === Status.Success) {
      return search.data;
    } if (search.status === Status.Loading) {
      return toNullable(search.data);
    }
  }, [search]);

  // AgGrid doesn't refresh when `rowData` is updated, so here we go
  useEffect(() => {
    if (agGridRef.current === null) { return }
    ((agGridRef.current as any).api as GridApi).setRowData(pageData?.element ?? []);
  }, [pageData]);

  // This is necessary in order to load the initial ordering model
  useEffect(() => {
    if (agGridRef.current === null) { return }
    ((agGridRef.current as any).api as GridApi).setSortModel([{ colId: sortOrder, sort: sortDirection.toLowerCase() }]);
  }, [sortOrder, sortDirection]);

  return (
    <>
      <StyledCard>
        <CardHeader
          title='Spare parts'
          action={(
            <SearchActions
              exportResults={() => exportSpareParts(query, selectedCategories)}
              exportStatus={exportStatus}
            />
          )}
        />
        <CategoryFilters />
        <StyledCardContent>
          <Box display='flex' overflow='auto' m={-2} flex={1}>
            <StyledBox className='ag-theme-material' flex={1} position='relative'>
              <AgGridReact
                defaultColDef={{
                  sortable: true
                }}
                ref={agGridRef}
                columnDefs={columns}
                onSortChanged={onAgGridSort}
                rowClass={classes.row}
                onGridReady={onAgGridReady}
                onPaginationChanged={onAgGridReady}
                getRowNodeId={row => row.externalId}
                // Only supports pixels number, no ems here :/
                headerHeight={32}
                rowHeight={ROW_HEIGHT}
                onRowClicked={viewClaimDetail}
                frameworkComponents={{
                  imageRenderer: (params: {value: any}) => (
                    <DetailImage
                      id={params.value.toString()}
                      maxHeight={ROW_HEIGHT}
                    />
                  ) }}
              />
              {search.status === Status.Error && (
              <Box
                position='absolute'
                top={32}
                width='100%'
                display='flex'
                height='calc(100% - 32px)'
                bgcolor='rgba(255,255,255,0.8)'
                justifyContent='center'
                alignItems='center'
                data-testid='search-error'
              >
                <ErrorMessage
                  title='We encountered an error'
                  message='Try again later'
                  icon='error'
                />
              </Box>
              )}
              {search.status === Status.Loading && (
                <TablePlaceholder />
              )}
            </StyledBox>
          </Box>
        </StyledCardContent>
      </StyledCard>
      <TablePagination
        pageSize={pageSize}
        setPageSize={setPageSize}
        setPage={lazySetPage}
        totalElements={fromNullable(pageData?.totalElements)}
        totalPages={fromNullable(pageData?.totalPages)}
        page={page}
      />
      <Detail />
    </>
  );
};

export default Search;
