/* eslint-disable react/jsx-props-no-spreading */
import { RootRef, Box, styled, Checkbox, FormControlLabel, Typography, Button } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import LoadingOverlay from '../../components/LoadingOverlay';
import { UploadDispatchProps } from './Upload';

const StyledLink = styled('a')(({ theme }) => ({
  color: theme.palette.primary.light,
  textDecoration: 'underline'
}));

const StyledBox = styled(Box)({
  cursor: 'pointer'
});

type UploadFormProps = Pick<UploadDispatchProps, 'uploadFile'> & { loading: boolean };

const UploadForm: React.FC<UploadFormProps> = ({ uploadFile, loading }) => {
  const { getRootProps, getInputProps } = useDropzone();
  const { ref, ...rootProps } = getRootProps();
  const [file, setFile] = useState<File>();
  const [shouldTruncate, setShouldTruncate] = useState(false);
  const handleFile = useCallback(
    () => uploadFile(file!, shouldTruncate),
    [uploadFile, file, shouldTruncate]
  );

  return (
    <RootRef rootRef={ref}>
      <StyledBox
        {...rootProps}
        border={2}
        borderColor='grey.300'
        borderRadius={8}
        p={2}
        mb={2}
        display='flex'
        justifyContent='center'
        alignItems='center'
        textAlign='center'
        maxWidth='40em'
        position='relative'
      >
        <input
          {...getInputProps()}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFile(event.target.files![0]!)}
        />
        <p>
          Drag and drop the file here, or
          <br />
          <StyledLink>browse your computer</StyledLink>
          <br />
          {file && (
            <Typography variant='subtitle2'>
              Current file:
              {file.name}
            </Typography>
          )}
        </p>
        {loading && <LoadingOverlay />}
      </StyledBox>
      <Box display='flex' flexDirection='row' justifyContent='space-between' py={1}>
        <FormControlLabel
          control={
            <Checkbox value={shouldTruncate} onChange={() => setShouldTruncate(x => !x)} />
          }
          label='Delete all existing entries before uploading'
        />
        <Button
          variant='contained'
          color='primary'
          onClick={handleFile}
          disabled={!file}
        >
          Upload
        </Button>
      </Box>
    </RootRef>

  );
};

export default UploadForm;
