import { Box, Button, CardContent, Grid, styled, Typography } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import { IO } from 'fp-ts/lib/IO';
import React, { useEffect, useRef, useState, useCallback } from 'react';

import { Category } from '../../store/models/Categories';
import State, { Status } from '../../store/models/State';
import { SelectedCategoryInfo } from '../../store/reducers/categories';
import CategoryChip from './CategoryChip';
import FiltersPopover from './FiltersPopover';

const SelectedFiltersIndicator = styled('div')(({ theme }) => ({
  height: 6,
  width: 6,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 8,
  marginLeft: -6,
  marginTop: 2
}));

export interface CategoryFiltersStateProps {
    categories: State<Record<string, Category[]>, string>
    selected: SelectedCategoryInfo[]
}

export interface CategoryFiltersDispatchProps {
    getCategories: IO<void>
    onSelectCategories: (categories: SelectedCategoryInfo[]) => void
    onDeselectCategories: (categories: SelectedCategoryInfo[]) => void
}

const CategoryFilters: React.FC<CategoryFiltersStateProps & CategoryFiltersDispatchProps> = ({
  categories, getCategories, selected, onSelectCategories, onDeselectCategories
}) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
  useEffect(() => {
    if (categories.status === Status.NotAsked) { getCategories() }
  }, [categories, getCategories]);

  const lazyNull = () => null;
  const deselect = useCallback(subcategory => () => onDeselectCategories([subcategory]), [onDeselectCategories]);

  return (
    <CardContent>
      <Grid container>
        <Grid item xs={9} md={10} lg={11}>
          <Box>
            {selected.map(subcategory => (
              <CategoryChip
                key={subcategory.subCategory}
                name={subcategory.subCategory}
                isSelected
                onSelect={lazyNull}
                onDeselect={deselect(subcategory)}
              />
            ))}
          </Box>
        </Grid>
        <Grid item xs={3} md={2} lg={1}>
          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            <Button
              ref={buttonRef}
              endIcon={(
                <>
                  <FilterIcon color={selected.length ? 'primary' : undefined} opacity={selected.length ? 0.6 : 1} />
                  {selected.length ? <SelectedFiltersIndicator /> : null}
                </>
              )}
              onClick={() => setPopoverOpen(true)}
              disabled={categories.status !== Status.Success}
              data-testid='filters-button'
            >
              <Typography variant='caption' color='textSecondary'>Filters</Typography>
            </Button>
          </Box>
          {categories.status === Status.Success && (
          <FiltersPopover
            onSelectCategories={onSelectCategories}
            onDeselectCategories={onDeselectCategories}
            categories={categories.data}
            selected={selected}
            open={popoverOpen}
            anchorRef={buttonRef.current}
            onClose={() => setPopoverOpen(false)}
          />
          )}
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default CategoryFilters;
