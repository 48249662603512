import { Card, CardHeader, CardContent, styled } from '@material-ui/core';
import { IO } from 'fp-ts/lib/IO';
import { toNullable } from 'fp-ts/lib/Option';
import React, { useEffect } from 'react';
import State, { Status } from '../../store/models/State';
import { UploadInfo } from '../../store/models/UploadInfo';
import LastUploadInfo from './LastUploadInfo';
import UploadForm from './UploadForm';

const ScrollableCard = styled(Card)({
  overflow: 'auto'
});

export interface UploadStateProps {
  status: Status,
  uploadInfo: State<UploadInfo, string>
}

export interface UploadDispatchProps {
  uploadFile: (file: File, force: boolean) => void
  getUploadInfo: IO<void>
}

const Upload: React.FC<UploadStateProps & UploadDispatchProps> = ({
  uploadFile,
  uploadInfo,
  getUploadInfo,
  status
}) => {
  useEffect(() => {
    getUploadInfo();
  }, [getUploadInfo]);

  const isUploading = (uploadInfo.status === Status.Success && uploadInfo.data.start && !uploadInfo.data.stop)
    || (uploadInfo.status === Status.Loading
      && toNullable(uploadInfo.data)?.start
      && !(toNullable(uploadInfo.data)?.stop));

  return (
    <ScrollableCard>
      <CardHeader title='Upload a new dataset file to update the catalogue' />
      <CardContent>
        {!isUploading && <UploadForm uploadFile={uploadFile} loading={status === Status.Loading} />}
        {(uploadInfo.status === Status.Success || uploadInfo.status === Status.Loading)
        && <LastUploadInfo uploadInfo={uploadInfo.data} />}
      </CardContent>
    </ScrollableCard>
  );
};

export default Upload;
