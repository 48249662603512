import { Option } from 'fp-ts/lib/Option';

export enum Status {
    Success = 'Success',
    Loading = 'Loading',
    Error = 'Error',
    NotAsked = 'NotAsked'
}

interface SuccessState<T> {
    status: Status.Success
    data: T
}

interface LoadingState<T> {
    status: Status.Loading
    data: Option<T>
}

interface ErrorState<S> {
    status: Status.Error,
    error: S
}

interface NotAskedState {
    status: Status.NotAsked
}

type State<T, S> = LoadingState<T> | SuccessState<T> | ErrorState<S> | NotAskedState;

export default State;
