import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

const LoaderOverlay = () => (
  <Box
    position='absolute'
    bgcolor='rgba(255,255,255,0.8)'
    height='100%'
    width='100%'
    display='flex'
    justifyContent='center'
    alignItems='center'
  >
    <CircularProgress color='primary' />
  </Box>
);

export default LoaderOverlay;
