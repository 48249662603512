import { connect } from 'react-redux';
import { logout } from '../../store/reducers/user';
import { RootState } from '../../store/store';
import RawUserInfo, { IUserInfoProps, IUserInfoDispatchProps } from './UserInfo';

const mapStateToProps = (state: RootState) => ({
  user: state.user
});

const mapDispatchToProps = ({
  logout
});

export default connect<IUserInfoProps, IUserInfoDispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(RawUserInfo);
