import { useCallback } from 'react';

/*
    Returns the lazy version of a given function:
    instead of having to pass `onClick={() => doSomething(x)}`
    you can just use `onClick={lazyDoSomething(x)}`
 */
export default function useLazy<T>(f: (x: T) => void) {
  return useCallback(x => () => f(x), [f]);
}
