import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { none, some } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import * as TE from 'fp-ts/lib/TaskEither';

import State, { Status } from '../models/State';
import { UploadInfo } from '../models/UploadInfo';
import UploadRepository from '../repositories/upload';
import { AppThunk } from '../store';

const UploadApi = new UploadRepository();

interface UploadState {
  info: State<UploadInfo, string>
  upload: State<void, string>
}

const initialState: UploadState = {
  info: {
    status: Status.NotAsked
  },
  upload: {
    status: Status.NotAsked
  },
};

export const slice = createSlice<UploadState, SliceCaseReducers<UploadState>>({
  name: 'upload',
  initialState,
  reducers: {
    _setUploadLoading: state => ({ ...state, upload: { status: Status.Loading, data: none } }),
    _setUploadError: (state, action: PayloadAction<string>) => ({ ...state,
      upload: {
        status: Status.Error,
        error: action.payload
      } }),
    _resetUpload: state => ({ ...state,
      upload:{
        status: Status.NotAsked,
      } }),
    _setInfoLoading: state => ({ ...state,
      info: {
        status: Status.Loading,
        data: state.info.status === Status.Success ? some(state.info.data) : none
      } }),
    _setInfoError: (state, action: PayloadAction<string>) => ({ ...state,
      info: {
        status: Status.Error,
        error: action.payload
      } }),
    _setInfoData: (state, action: PayloadAction<UploadInfo>) => ({ ...state,
      info: {
        status: Status.Success,
        data: action.payload
      } }),
  }
});

export const getUploadInfo = (): AppThunk => (dispatch, getState) => {
  const { _setInfoLoading, _setInfoError, _setInfoData } = slice.actions;
  dispatch(_setInfoLoading(null));
  return pipe(
    UploadApi.checkStatus,
    TE.bimap(
      err => dispatch(_setInfoError(err)),
      x => {
        dispatch(_setInfoData(x));
        if (x.start && !x.stop) {
          setTimeout(() => getUploadInfo()(dispatch, getState, null), 1000);
        }
      }
    )
  )();
};

export const uploadFile = (file: File, force: boolean): AppThunk => (dispatch, getState) => {
  const { _setUploadLoading, _setUploadError, _resetUpload } = slice.actions;
  dispatch(_setUploadLoading(null));
  return pipe(
    UploadApi.upload(file, force),
    TE.bimap(
      err => dispatch(_setUploadError(err)),
      () => {
        dispatch(_resetUpload(null));
        getUploadInfo()(dispatch, getState, null);
      }
    )
  )();
};

export default slice.reducer;
