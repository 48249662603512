import { createMuiTheme } from '@material-ui/core/styles';
import color from 'color';
import merge from 'lodash.merge';
import mainTheme from './schindler';

const drawerWidth = mainTheme.mixins.drawer.width;

const customColors = {
  drawerBg: '#1c2530',
  drawerHeaderBg: '#23303E'
};

const sidebarThemeCustomization = {
  typography: {

  },
  palette: {
    ...customColors
  },
  overrides: {
    MuiDrawer: {
      root: {
        width: drawerWidth,
        flexShrink: 0
      },
      paper: {
        background: customColors.drawerBg,
        width: drawerWidth,
        color: 'rgba(255,255,255,0.75)'
      }
    },
    MuiListItem: {
      root: {
        backgroundColor: 'inherit',
        color: 'inherit',
        '&:focus': {
          color: 'white'
        },
        '&:hover': {
          backgroundColor: `${color(mainTheme.palette.primary.main).alpha(0.1)} !important`,
          color: 'white'
        },
        '&$selected': {
          backgroundColor: `${color(mainTheme.palette.primary.main).alpha(0.1)} !important`,
          borderLeft: `2px solid ${color(mainTheme.palette.primary.main)}`,
          color: 'white',
        }
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit'
      }
    }
  },
  props: {
    MuiListItemText: {
      primaryTypographyProps: {
        variant: 'subtitle2'
      }
    }
  }
};

const sidebarTheme = createMuiTheme(merge({}, mainTheme, sidebarThemeCustomization));

export default sidebarTheme;
