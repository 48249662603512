import { styled } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { IO } from 'fp-ts/lib/IO';
import * as O from 'fp-ts/lib/Option';
import React from 'react';

const StyledButton = styled(Button)({
  borderRadius: '2px',
  minWidth: 'unset',
  padding: '0.5em 0.5em',
  '&:nth-of-type(1)': {
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0'
  },
  '&:nth-of-type(2)': {
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    borderLeft: '0'
  }
});

interface TablePaginationProps {
  pageSize: number
  page: number
  setPage: (nextPage: number) => IO<void>
  setPageSize: (nextSize: number) => void
  totalPages: O.Option<number>
  totalElements: O.Option<number>
}

const TablePagination: React.FunctionComponent<TablePaginationProps> = ({
  page,
  setPage,
  pageSize,
  setPageSize,
  totalPages,
  totalElements
}) => {
  const renderTotalElements = O.getOrElse<number | string>(() => '...');
  return (
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='center'
      alignItems='center'
      px={2}
      marginBottom={-1}
      marginTop={2}
    >
      <Box display='flex' alignItems='center' mr={1}>
        <Box mr={1}>
          <Typography variant='body2' color='textSecondary'>
            Rows per page
          </Typography>
        </Box>
        <Select
          value={pageSize}
          variant='outlined'
          onChange={x => setPageSize(Number(x.target.value))}
          SelectDisplayProps={{
            // @ts-ignore
            'data-testid': 'pagesize-select'
          }}
        >
          {[10, 20, 25, 50, 100].map(
            size => (
              <MenuItem key={size} value={size} data-testid={`pagesize-option-${size}`}>
                {size}
              </MenuItem>
            )
          )}
        </Select>
      </Box>
      <Box display='inline-flex' flexDirection='row' alignItems='center' ml={1}>
        <Box display='inline-flex' alignItems='center' mr={2}>
          <Typography variant='body2' color='textSecondary'>
            {`${Number(page) * pageSize + 1} - ${Number(page + 1) * pageSize} of ${renderTotalElements(totalElements)}`}
          </Typography>
        </Box>
        <StyledButton
          disabled={page === 0 || O.isNone(totalPages)}
          onClick={setPage(page - 1)}
          variant='outlined'
          data-testid='pagination-go-previous'
        >
          <ChevronLeftIcon />
        </StyledButton>
        <StyledButton
          disabled={O.isNone(totalPages) || !totalPages.value || (page === (totalPages.value - 1))}
          onClick={setPage(page + 1)}
          variant='outlined'
          data-testid='pagination-go-next'
        >
          <ChevronRightIcon />
        </StyledButton>
      </Box>
    </Box>
  );
};

export default TablePagination;
