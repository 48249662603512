import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Status } from '../../store/models/State';
import { getSparePart, resetSparePart } from '../../store/reducers/spareparts';
import { RootState } from '../../store/store';
import Detail, { IDetailDispatchProps, IDetailStateProps } from './Detail';

const mapStateToProps = (state: RootState) => ({
  detail: state.spareParts.detail,
  isSupplyChainUser: state.user.status === Status.Success
    && !!state.user.data.roles.find(x => x.trim().toLowerCase() === 'sc' || x.trim().toLowerCase() === 'admin')
});

const mapDispatchToProps = ({
  getSparePart, resetSparePart
});

export default connect<
  IDetailStateProps,
  IDetailDispatchProps,
  {},
  RootState
  >(mapStateToProps, mapDispatchToProps)(
    withRouter(Detail)
  );
