import { IO } from 'fp-ts/lib/IO';
import React, { useEffect } from 'react';

import App from '../../views/Skeleton/App';
import SessionExpiredModal from '../SessionExpiredModal';

export interface IAppBootstrapStateProps {
    isLoggedIn: boolean
    isSessionExpired: boolean
}

export interface IAppBootstrapDispatchProps {
    login: IO<void>
}

const AppBootstrap: React.FC<IAppBootstrapStateProps & IAppBootstrapDispatchProps> = (
  { login, isLoggedIn, isSessionExpired }
) => {
  useEffect(() => { login() }, [login]);
  return (
    <>
      {!isLoggedIn && !isSessionExpired && <div>Loading</div>}
      {(isLoggedIn || isSessionExpired) && <App />}
      {isSessionExpired && <SessionExpiredModal />}
    </>
  );
};

export default AppBootstrap;
