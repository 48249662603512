import axios from 'axios';
import { pipe } from 'fp-ts/lib/pipeable';
import * as TE from 'fp-ts/lib/TaskEither';
import getError from '../../utils/getError';
import { CategoriesCodec } from '../models/Categories';

class CategoriesRepository {
    get = pipe(
      TE.tryCatch<any, unknown>(
        () => axios.get('/api/searchengine/categories/')
          .then(
            x => x.data,
            err => { throw err }
          ),
        getError
      ),
      TE.chain(x => TE.fromEither(CategoriesCodec.decode(x))),
    );
}

export default CategoriesRepository;
