import { connect } from 'react-redux';
import { getUploadInfo, uploadFile } from '../../store/reducers/upload';
import { RootState } from '../../store/store';
import Upload, { UploadStateProps, UploadDispatchProps } from './Upload';

const mapStateToProps = (state: RootState) => ({
  status: state.upload.upload.status,
  uploadInfo: state.upload.info
});

const mapDispatchToProps = ({
  uploadFile,
  getUploadInfo
});

export default connect<UploadStateProps, UploadDispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(Upload);
