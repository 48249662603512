import { Box, Divider, Popover, styled, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { IO } from 'fp-ts/lib/IO';
import groupBy from 'lodash.groupby';
import React, { useMemo } from 'react';

import { Category } from '../../store/models/Categories';
import { SelectedCategoryInfo } from '../../store/reducers/categories';
import CategorySection from './CategorySection';

interface FiltersPopoverProps {
    categories: Record<string, Category[]>
    selected: SelectedCategoryInfo[]
    open: boolean
    anchorRef: HTMLElement | null
    onClose: IO<void>
    onSelectCategories: (categories: SelectedCategoryInfo[]) => void
    onDeselectCategories: (categories: SelectedCategoryInfo[]) => void
}

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(1)}px ${-2 * theme.spacing(1)}px 0 ${-2 * theme.spacing(1)}px`
}));

const FiltersPopover: React.FC<FiltersPopoverProps> = ({
  open,
  anchorRef,
  onClose,
  categories,
  selected,
  onSelectCategories,
  onDeselectCategories
}) => {
  const theme = useTheme();
  const isMdOrBigger = useMediaQuery(theme.breakpoints.up('md'));
  const popoverMaxWidth = isMdOrBigger ? '600px' : '80vw';

  const groupedSelectedCategories = useMemo(() => groupBy(selected, x => x.category), [selected]);
  return (
    <Popover
      onClose={onClose}
      anchorEl={anchorRef}
      open={open}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      PaperProps={{ style: { maxWidth: popoverMaxWidth } }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      data-testid='filters-popover'
    >
      {/* zIndex is necessary here because checkboxes are on zIndex=2 */}
      <Box px={2} paddingTop={2} position='sticky' bgcolor='white' top={0} width='100%' zIndex={2}>
        <Typography variant='subtitle1' color='textSecondary'>Select the parameters of your search</Typography>
        <StyledDivider />
      </Box>
      <Box>
        {Object.values(categories)
          .map(x => (
            <CategorySection
              key={x[0].category}
              onSelectCategories={onSelectCategories}
              onDeselectCategories={onDeselectCategories}
              selected={groupedSelectedCategories[x[0].category] || []}
              subcategories={x}
            />
          ))}
      </Box>
    </Popover>
  );
};

export default FiltersPopover;
