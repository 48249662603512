import { Box, Divider, IconButton, Typography, AppBar, Toolbar } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IO } from 'fp-ts/lib/IO';
import React, { ReactChild } from 'react';

export interface HeadersProps {
  onClose: IO<void>
  headerName: ReactChild
}

const StyledToolbar = styled(Toolbar)({
  display: 'flex',
  justifyContent: 'space-between'
});

const DetailHeader: React.FC<HeadersProps> = ({ headerName, onClose }) => (
  <AppBar
    component='header'
    position='sticky'
    elevation={0}
    color='inherit'
  >
    <StyledToolbar disableGutters>
      <Box display='flex' flexDirection='row' alignItems='center' px={2}>
        <Box component='span' display='flex' mr={2}>
          <Typography color='textSecondary' variant='caption'>ITEM</Typography>
        </Box>
        <Typography>{headerName}</Typography>
      </Box>
      <Box display='flex' flex={1} justifyContent='flex-end' pr={2}>
        <IconButton
          onClick={onClose}
          data-testid='close-detail'
        >
          <CloseIcon />
        </IconButton>
      </Box>
    </StyledToolbar>
    <Divider />
  </AppBar>
);

export default DetailHeader;
