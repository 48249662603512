import Box from '@material-ui/core/Box';
import React from 'react';

import useSidebar from '../../utils/useSidebar';
import AppBar from './AppBar';
import Routes from './Routes';
import Sidebar from './Sidebar';

const App: React.FC = () => {
  const { drawerOpen, closeDrawer, toggleDrawer, showPermanentSidebar } = useSidebar();

  return (
    <Box display='flex' height='100vh' bgcolor='grey.100'>
      <Sidebar open={drawerOpen} onClose={closeDrawer} permanent={showPermanentSidebar} />
      <Box display='flex' flexDirection='column' flexGrow={1} overflow='hidden'>
        <AppBar toggleDrawer={toggleDrawer} />
        <Box display='flex' flexDirection='column' flexGrow={1} overflow='auto' p={4}>
          <Routes />
        </Box>
      </Box>
    </Box>
  );
};

export default App;
