import { createMuiTheme } from '@material-ui/core/styles';
import createPalette from '@material-ui/core/styles/createPalette';

const brandColor = '#dc0000';

const palette = createPalette({ primary: {
  main: brandColor,
  light: '#F95353'
} });

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      'IBM Plex Sans',
      'Segoe UI',
      'Roboto'
    ].join(','),
    h5: {
      fontWeight: 'bold'
    },
    button: {
      letterSpacing: '1px'
    },
    overline: {
      fontSize: '0.625rem',
      letterSpacing: '0.5px'
    },
  },
  palette,
  mixins: {
    drawer: {
      width: '22em'
    }
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: '0',
        color: brandColor,
      },
      root: {
        borderRadius: '200px'
      }
    },
    MuiCardHeader: {
      root: {
        borderBottom: '1px solid #eaeBf0',
        padding: '13px 20px'
      },
      action: {
        marginRight: '0',
        marginTop: '0'
      }
    },
    MuiOutlinedInput: {
      input: {
        padding: '1em'
      }
    },
    MuiFilledInput: {
      input: {
        padding: '1em'
      }
    },
    MuiAutocomplete: {
      inputRoot: {
        padding: '0.3em !important'
      }
    },
    MuiInputLabel: {
      outlined: {
        transform: 'translate(1em, 1em) scale(1)'
      },
      filled: {
        transform: 'translate(1em, 1em) scale(1)'
      }
    },
    MuiDialogActions: {
      root: {
        borderTop: `1px solid ${palette.grey['100']}`,
        marginTop: '1em'
      }
    },
    MuiSelect: {
      root: {
        background: 'white'
      }
    },
    MuiChip: {
      root: {
        textTransform: 'capitalize'
      },
      outlined: {
        backgroundColor: palette.grey[200],
        border: `2px solid ${palette.grey[300]}`,
      },
      outlinedPrimary: {
        '&:focus': {
          backgroundColor: `${palette.primary.main} !important`,
        },
        '&:hover': {
          backgroundColor: `${palette.primary.dark} !important`,
        },
        backgroundColor: palette.primary.light,
        color: 'white',
        border: `2px solid ${palette.primary.main}`,
      },
      deleteIconOutlinedColorPrimary: {
        color: 'white'
      }
    },
  },
  props: {
    MuiCardHeader: {
      titleTypographyProps: {
        variant: 'subtitle2'
      }
    },
    MuiDialogContentText: {
      variant: 'body2'
    }
  }
});

export default theme;
