import * as A from 'fp-ts/lib/Array';
import { constFalse, constTrue, flow, not, Predicate } from 'fp-ts/lib/function';
import * as O from 'fp-ts/lib/Option';
import { Option } from 'fp-ts/lib/Option';
import React from 'react';
import { Discount } from '../../store/models/SpareParts';
import { fullPriceFormatter } from '../../utils/currencyFormatter';
import { TitleValueSupplier } from './DetailFields';
import { hasRange } from './DiscountsTable';

// return a function that accept an array of discounts and extract a value from the first element via the function f.
// If the array is null undefined or empty return the value of getOrElse function
const valueFromFirstDiscount = <T, R, > (
  f: (discount: Discount) => T, getOrElse: (c: Option<T>) => R, filter: Predicate<Discount> = constTrue
) => flow(
    (d?: Array<Discount>) => O.fromNullable(d),
    O.map(A.head),
    O.flatten,
    O.filter(filter),
    O.map(f),
    getOrElse,
  );
const computedLeadTimeFromFirstDiscount = valueFromFirstDiscount(
  discount => discount.computedLeadTime,
  O.toUndefined,
);
const priceWithDapFeeFromFirstDiscount = valueFromFirstDiscount(discount => discount.priceWithDapFee, O.toUndefined);
// do not know why they want the computed only when there is no range
// https://gitlab.welld.io/schindler/marketplaceapp/ui/-/issues/62
const incotermFromFirstDiscount = valueFromFirstDiscount(discount => discount.incoterm, O.toUndefined, not(hasRange));
const firstDiscountHasRange = valueFromFirstDiscount(hasRange, O.getOrElse(constFalse));

export const firstSection: Array<TitleValueSupplier> = [
  { title: 'MATERIAL', value: detail => detail.externalId },
  { title: 'CATEGORIES', value: detail => detail.category },
  { title: 'SUBCATEGORIES', value: detail => detail.subcategory },
  { title: 'DESCRIPTION', value: detail => `${detail.description}`, fullWidth: true },
  { title: 'PACKAGING', value: () => 'Included' },
];

export const secondSection: Array<TitleValueSupplier> = [
  { title: 'DIMENSION', value: detail => detail.dimension },
  { title: 'WEIGHT', value: detail => detail.weight },
  { title: 'DRAWING', value: detail => detail.version },
  { title: 'CONFIGURABLE', value: detail => (detail.configurable ? 'Yes' : 'No') },
];

export const thirdSection: (isSupplyChainUser: boolean) => Array<TitleValueSupplier> = isSupplyChainUser => [
  {
    title: 'LEAD TIME',
    value: detail => {
      let leadTime;
      if (!isSupplyChainUser) {
        leadTime = computedLeadTimeFromFirstDiscount(detail.discounts);
      }
      leadTime = leadTime || detail.leadTime;
      return (leadTime ? `${leadTime} WD` : undefined);
    }
  },
  {
    title: 'PRICE',
    value: detail => {
      // if is not a SupplyChainUser and has discounts range the prices will be shown in the range table and not here
      if (!isSupplyChainUser && firstDiscountHasRange(detail.discounts)) {
        return undefined;
      }

      let price;
      if (!isSupplyChainUser) {
        price = priceWithDapFeeFromFirstDiscount(detail.discounts);
      }
      price = price || detail.price;

      return (price
        ? `${fullPriceFormatter.format(price)} ${detail.currency}`
        : <a href={process.env.REACT_APP_OFFERS_LINK} rel='norel noopener'>Please contact offer DB</a>);
    },
  },
  { title: 'LOT SIZE', value: detail => `${detail.lotSize || ''} ${detail.lotSizeUom || ''}` },
  { title: 'MIN ORDER QNT', value: detail => `${detail.minQuantity || ''} ${detail.minQuantityUom || ''}` },
  { title: 'HS CODE', value: detail => detail.hsCode },
  { title: 'CWH MATERIAL', value: detail => detail.cwhMaterial },
  {
    title: 'INCOTERM',
    value: detail => detail.incoterm || (
      !isSupplyChainUser ? incotermFromFirstDiscount(detail.discounts) : undefined
    )
  },
  { title: 'FAST ORDER', value: detail => (detail.fastOrder ? 'Yes' : 'No') },
  { title: 'SUPPLIER', value: detail => detail.supplier },
];
