import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { difference, union } from 'fp-ts/lib/Array';
import { fromEquals } from 'fp-ts/lib/Eq';
import { none } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import * as TE from 'fp-ts/lib/TaskEither';
import groupBy from 'lodash.groupby';

import categoryToSelectionData from '../../components/CategoryFilters/utils';
import { Category } from '../models/Categories';
import State, { Status } from '../models/State';
import CategoriesRepository from '../repositories/categories';
import { AppThunk } from '../store';

const CategoriesApi = new CategoriesRepository();

export interface SelectedCategoryInfo {
  category: string
  subCategory: string
}

const isSameCategory = fromEquals<SelectedCategoryInfo>((a, b) => a.subCategory === b.subCategory);

interface CategoriesState {
  categories: State<Record<string, Category[]>, string>
  selected: SelectedCategoryInfo[]
}

const initialState: CategoriesState = {
  categories: {
    status: Status.NotAsked
  },
  selected: []
};

export const slice = createSlice<CategoriesState, SliceCaseReducers<CategoriesState>>({
  name: 'categories',
  initialState,
  reducers: {
    _setLoading: state => ({ ...state, categories: { status: Status.Loading, data: none } }),
    _setError: (state, action: PayloadAction<string>) => ({ ...state,
      categories: {
        status: Status.Error,
        error: action.payload
      } }),
    _setData: (state, action: PayloadAction<Record<string, Category[]>>) => ({ ...state,
      categories:{
        status: Status.Success,
        data: action.payload
      } }),
    selectCategories: (state, action: PayloadAction<SelectedCategoryInfo[]>) => ({
      ...state,
      selected: union(isSameCategory)(action.payload, state.selected)
    }),
    deselectCategories: (state, action: PayloadAction<SelectedCategoryInfo[]>) => ({
      ...state,
      selected: difference(isSameCategory)(state.selected, action.payload)
    }),
    selectCategoriesFromIds: (state, action: PayloadAction<string[]>) => {
      if (state.categories.status === Status.Success && !!state.categories.data) {
        const flatCategories: Category[] = Object.values(state.categories.data).flat();
        return {
          ...state,
          selected: action.payload
            .map(x => flatCategories
              .find((category: Category) => x === category.subcategory))
            .filter(Boolean) // Cast to boolean works as a null-check
            .map(x => categoryToSelectionData(x!))
        };
      }
      return state;
    }
  }
});

const { selectCategories, deselectCategories, selectCategoriesFromIds } = slice.actions;
export { selectCategories, deselectCategories, selectCategoriesFromIds };

export const getCategories = (): AppThunk => dispatch => {
  const { _setLoading, _setError, _setData } = slice.actions;
  dispatch(_setLoading(null));
  return pipe(
    CategoriesApi.get,
    TE.map(x => groupBy(x, element => element.category)),
    TE.bimap(
      err => dispatch(_setError(err)),
      x => dispatch(_setData(x))
    )
  )();
};

export default slice.reducer;
