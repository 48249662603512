import { connect } from 'react-redux';
import { Status } from '../../store/models/State';
import { login, SessionExpired } from '../../store/reducers/user';
import { RootState } from '../../store/store';
import AppBootstrap, { IAppBootstrapStateProps, IAppBootstrapDispatchProps } from './AppBootstrap';

const mapStateToProps = (state: RootState) => ({
  isLoggedIn: state.user.status === Status.Success,
  isSessionExpired: state.user.status === Status.Error && state.user.error === SessionExpired
});

const mapDispatchToProps = ({
  login
});

export default connect<IAppBootstrapStateProps, IAppBootstrapDispatchProps, {}, RootState>(
  mapStateToProps,
  mapDispatchToProps
)(AppBootstrap);
