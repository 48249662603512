import { Box, Typography, TableHead, Table, TableRow, TableCell, TableBody, Divider } from '@material-ui/core';
import * as O from 'fp-ts/lib/Option';
import React from 'react';
import { UploadInfo } from '../../store/models/UploadInfo';

interface LastUploadInfoProps {
  uploadInfo: UploadInfo | O.Option<UploadInfo>
}

const iso8601ToLocaleString = (originalDate: string) => (new Date(Date.parse(originalDate))).toLocaleString();

const SheetsUploadDetail: React.FC<{sheets: UploadInfo['sheets']}> = ({ sheets }) => (
  <Table>
    <TableHead>
      <TableRow>
        <TableCell>Sheet name</TableCell>
        <TableCell>Processed</TableCell>
        <TableCell>Imported</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {Object.entries(sheets!).map(([name, value]) => (
        <TableRow>
          <TableCell>{name}</TableCell>
          <TableCell>{value.parsedRecords}</TableCell>
          <TableCell>{value.imported}</TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
);

// eslint-disable-next-line no-underscore-dangle
const isOption = <T extends any>(x: any): x is O.Option<T> => x && x._tag && (x._tag === 'None' || x._tag === 'Some');

const LastUploadInfo: React.FC<LastUploadInfoProps> = ({ uploadInfo }) => {
  if (isOption(uploadInfo) && O.isNone(uploadInfo)) { return null }
  const info = isOption(uploadInfo) ? O.toNullable(uploadInfo) : uploadInfo;
  return (
    <>
      {
        info?.stop && <Divider />
      }
      <Box mt={2}>
        <Typography variant='subtitle1'>Last upload</Typography>
        {info!.start && (
        <Typography variant='body2'>
          Started at:&nbsp;
          {iso8601ToLocaleString(info!.start)}
        </Typography>
          )}
        {!!info!.stop && (
        <Typography variant='body2'>
          Finished at:&nbsp;
          {iso8601ToLocaleString(info!.stop)}
        </Typography>
        )}
        {!!info!.sheets
        && (
          <SheetsUploadDetail sheets={info!.sheets} />
        )}
        {(info!.error!.length > 0) && (
        <Box mt={1}>
          <Typography color='primary'>The uploaded file contains errors:</Typography>
          <ul>
            {
                info!.error!.map(x => <li>{x}</li>)
              }
          </ul>
        </Box>
        )}
      </Box>
    </>
  );
};

export default LastUploadInfo;
