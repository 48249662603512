import { styled, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import { IO } from 'fp-ts/lib/IO';
import React from 'react';

interface ErrorMessageProps {
  title: string,
  message: string,
  icon: 'error' | 'warning',
  action?: IO<void>
  actionLabel?: string
}

const getErrorIcon = (icon: ErrorMessageProps['icon']) => styled(
  icon === 'warning'
    ? SentimentDissatisfiedIcon
    : SentimentVeryDissatisfiedIcon
)(
  ({ theme }) => ({
    fontSize: '4em',
    color: theme.palette.grey['300']
  })
);

const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({
  title,
  message,
  icon,
  action,
  actionLabel
}) => {
  const ErrorIcon = getErrorIcon(icon);
  return (
    <Box
      m={4}
      display='flex'
      flexDirection='column'
      alignItems='center'
      borderRadius='1em'
      py={6}
      px={4}
      position='relative'
      minWidth='33%'
      flexGrow={1}
      justifyContent='center'
      data-testid='error-container'
    >
      <Box
        borderRadius='100%'
        p={3}
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <ErrorIcon />
      </Box>
      <Box textAlign='center'>
        <Typography variant='h6'>{title}</Typography>
        <Typography variant='subtitle1'>{message}</Typography>
      </Box>
      {action && (
      <Button
        color='primary'
        variant='contained'
        onClick={action}
      >
        {actionLabel}
      </Button>
      )}
    </Box>
  );
};

export default ErrorMessage;
