import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useCallback, useEffect, useState } from 'react';

const useSidebar = () => {
  const theme = useTheme();
  const showPermanentSidebar = useMediaQuery(theme.breakpoints.up('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  useEffect(() => setDrawerOpen(showPermanentSidebar), [showPermanentSidebar]);

  const closeDrawer = useCallback(() => setDrawerOpen(false), []);
  const toggleDrawer = useCallback(() => setDrawerOpen(open => !open), []);

  return { showPermanentSidebar, closeDrawer, drawerOpen, toggleDrawer };
};

export default useSidebar;
