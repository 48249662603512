import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { head } from 'fp-ts/lib/Array';
import { filter, map, toNullable } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import React from 'react';
import { Discount, SparePart } from '../../store/models/SpareParts';
import { currencyFormatter, percentageFormatter } from '../../utils/currencyFormatter';

interface DiscountsTableProps {
    discounts: SparePart['discounts'],
    isSupplyChainUser: boolean,
}

interface DiscountsRangeTableProps {
  discount: Discount,
  showDiscount: boolean,
}

interface DiscountRowProps {
  priceStockSize?: number | null,
  priceStockDiscount?: number | null,
  priceStock?: number | null,
  showDiscount: boolean
}

export const hasRange = (discount: Discount) => [
  discount.priceStock1Size,
  discount.priceStock2Size,
  discount.priceStock3Size
].some(x => x != null);

const FirstDiscountRangeTable: React.FC<{discounts: Array<Discount>}> = ({ discounts }) => pipe(
  head(discounts),
  filter(hasRange),
  map(discount => (
    <DiscountsRangeTable
      discount={discount}
      showDiscount={false}
    />
  )),
  toNullable
);

const DiscountRow: React.FC<DiscountRowProps> = (
  {
    priceStockSize,
    priceStockDiscount,
    priceStock,
    showDiscount
  }
) => (
  priceStockSize != null ? (
    <TableRow>
      <TableCell>
        {priceStockSize}
      </TableCell>
      {
          showDiscount ? (
            <TableCell>
              {percentageFormatter.format(priceStockDiscount!)}
            </TableCell>
          ) : null
        }
      <TableCell>
        {currencyFormatter.format(priceStock!!)}
      </TableCell>
    </TableRow>
  ) : null
);

const DiscountsRangeTable: React.FC<DiscountsRangeTableProps> = ({ discount, showDiscount }) => (
  <Table>
    <TableHead>
      <TableCell>
        Stock Size
      </TableCell>
      {
          showDiscount ? (
            <TableCell>
              Discount %
            </TableCell>
          ) : null
        }
      <TableCell>
        Price
      </TableCell>
    </TableHead>
    <TableBody>
      <DiscountRow
        priceStockSize={discount.priceStock1Size}
        priceStockDiscount={discount.priceStock1Discount}
        priceStock={discount.priceStock1}
        showDiscount={showDiscount}
      />
      <DiscountRow
        priceStockSize={discount.priceStock2Size}
        priceStockDiscount={discount.priceStock2Discount}
        priceStock={discount.priceStock2}
        showDiscount={showDiscount}
      />
      <DiscountRow
        priceStockSize={discount.priceStock3Size}
        priceStockDiscount={discount.priceStock3Discount}
        priceStock={discount.priceStock3}
        showDiscount={showDiscount}
      />
    </TableBody>
  </Table>
);

const DiscountsTable: React.FC<DiscountsTableProps> = ({ discounts, isSupplyChainUser }) => {
  if (!discounts || discounts.length === 0) { return null }

  return isSupplyChainUser ? (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Customer Name</TableCell>
          <TableCell align='right'>P. ID</TableCell>
          <TableCell align='right'>P. NAME</TableCell>
          <TableCell align='right'>DAP Fee</TableCell>
          <TableCell align='right'>DAP +LT</TableCell>
          <TableCell align='right'>LT from contracts</TableCell>
          <TableCell align='right'>Incoterm</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {discounts.map(discount => (
          <>
            <TableRow key={discount.partyId}>
              <TableCell component='th' scope='row'>
                {discount.customerName}
              </TableCell>
              <TableCell align='right'>{discount.partyId}</TableCell>
              <TableCell align='right'>{discount.partyName}</TableCell>
              <TableCell align='right'>
                {percentageFormatter.format(discount.dapFee || 0)}
              </TableCell>
              <TableCell align='right'>{discount.dapLeadTimeExtra}</TableCell>
              <TableCell align='right'>{discount.leadTimeFromContracts}</TableCell>
              <TableCell align='right'>{discount.incoterm}</TableCell>
            </TableRow>
            { hasRange(discount) && (
              <TableRow>
                <TableCell />
                <TableCell colSpan={6}>
                  <DiscountsRangeTable
                    discount={discount}
                    showDiscount
                  />
                </TableCell>
              </TableRow>
            )}
          </>
        ))}
      </TableBody>
    </Table>
  ) : (
    <FirstDiscountRangeTable discounts={discounts} />
  );
};

export default DiscountsTable;
