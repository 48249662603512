import { styled, Chip } from '@material-ui/core';
import { IO } from 'fp-ts/lib/IO';
import React from 'react';

interface CategoryChipProps {
    onSelect: IO<void>
    onDeselect: IO<void>
    isSelected: boolean
    name: string
}

const PaddedChip = styled(Chip)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

const CategoryChip: React.FC<CategoryChipProps> = ({ onSelect, onDeselect, isSelected, name }) => (
  <PaddedChip
    onClick={isSelected ? onDeselect : onSelect}
    variant='outlined'
    color={isSelected ? 'primary' : 'default'}
    onDelete={isSelected ? onDeselect : undefined}
    label={name}
    data-testid='category-chip'
  />
);

export default CategoryChip;
