import ButtonBase from '@material-ui/core/ButtonBase';
import Hidden from '@material-ui/core/Hidden';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { IO } from 'fp-ts/lib/IO';
import React from 'react';

import { Status } from '../../store/models/State';
import { RootState } from '../../store/store';
import UserAvatar from './UserAvatar';
import UserDescription from './UserDescription';
import UserMenu from './UserMenu';

export interface IUserInfoProps {
    user: RootState['user']
}

export interface IUserInfoDispatchProps {
    logout: IO<void>
}

const RawUserInfo: React.FC<IUserInfoProps & IUserInfoDispatchProps> = ({ user, logout }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement>();

  const handleClick: React.MouseEventHandler<HTMLButtonElement> = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };

  switch (user.status) {
    case (Status.Success):
      return (
        <>
          <ButtonBase onClick={handleClick}>
            <UserAvatar profile={user.data} />
            <Hidden xsDown>
              <UserDescription profile={user.data} />
              <KeyboardArrowDownIcon />
            </Hidden>
          </ButtonBase>
          <UserMenu anchorEl={anchorEl} onClose={handleClose} logout={logout} />
        </>
      );
    default: return null;
  }
};

export default RawUserInfo;
