import * as t from 'io-ts';

const SortingCodec = t.type({
  direction: t.union([t.literal('ASC'), t.literal('DESC')]),
  order: t.string
});

export const PageCodec = <C extends t.Mixed>(codec: C) => t.type({
  pageNumber: t.number,
  element: t.array(codec),
  totalPages: t.number,
  totalElements: t.number,
  sort: SortingCodec
});

interface Page<T> extends Omit<t.TypeOf<ReturnType<typeof PageCodec>>, 'element'>{
    element: T[]
}

export default Page;
