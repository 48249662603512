import { createSlice, PayloadAction, SliceCaseReducers } from '@reduxjs/toolkit';
import { none } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import * as TE from 'fp-ts/lib/TaskEither';

import ioToTe from '../../utils/ioToTe';
import State, { Status } from '../models/State';
import User, { UserCodec } from '../models/User';
import KeycloakRepository, { KeycloakProfileWithRoles } from '../repositories/keycloak';
import { AppThunk } from '../store';

const KcApi = new KeycloakRepository();

export const SessionExpired = { _tag: 'SessionExpired' } as const;
export type SessionExpired = typeof SessionExpired;

type UserState = State<User, string | SessionExpired>;

const initialState: UserState = {
  status: Status.NotAsked
};

export const slice = createSlice<UserState, SliceCaseReducers<UserState>>({
  name: 'user',
  initialState,
  reducers: {
    _login: (_, action: PayloadAction<User>) => ({ status: Status.Success, data: action.payload }),
    _setLoading: () => ({ status: Status.Loading, data: none }),
    _setError: (state, action: PayloadAction<string | SessionExpired>) => {
      if (state.status === Status.Error && state.error === SessionExpired) { return state }
      return {
        status: Status.Error,
        error: action.payload };
    },
    _logout: () => initialState
  }
});

export const logout = (): AppThunk => dispatch => {
  const { _logout } = slice.actions;
  KcApi.logout();
  dispatch(_logout(null));
};

export const login = (): AppThunk => dispatch => {
  const { _setLoading, _setError, _login } = slice.actions;
  dispatch(_setLoading(null));

  return pipe(
    KcApi.init(),
    TE.chain(KcApi.getProfile),
    TE.chainFirst<any, KeycloakProfileWithRoles, void>(
      () => ioToTe(
        KcApi.bootstrapAxios(
          () => dispatch(_setError(SessionExpired))
        )
      )
    ),
    TE.chain(x => TE.fromEither(UserCodec.decode(x))),
    TE.bimap(
      err => dispatch(_setError(err)),
      x => dispatch(_login(x))
    ),
  )();
};

export default slice.reducer;
