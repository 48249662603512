import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import NoteIcon from '@material-ui/icons/Note';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import SearchIcon from '@material-ui/icons/Search';
import { IO } from 'fp-ts/lib/IO';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import { Status } from '../../../store/models/State';
import { RootState } from '../../../store/store';
import sidebarTheme from '../../../theme/sidebar';
import LinkMenuItem from './LinkMenuItem';
import MenuItem from './MenuItem';
import SidebarHeader from './SidebarHeader';

interface ISidebarOwnProps {
    permanent: boolean
    open: boolean
    onClose: IO<void>
}

const Sidebar: React.FC<ISidebarOwnProps & RouteComponentProps> = ({ permanent, open, onClose, location }) => {
  const matchPath = useCallback(pathRegex => pathRegex.test(location.pathname), [location.pathname]);
  const isAdmin = useSelector((state: RootState) => state.user.status === Status.Success
    && state.user.data.roles.includes('admin'));

  return (
    <MuiThemeProvider theme={sidebarTheme}>
      <Drawer
        anchor='left'
        variant={permanent ? 'persistent' : 'temporary'}
        open={open}
        onClose={onClose}
      >
        <SidebarHeader />
        <List disablePadding>
          <MenuItem
            title='Search'
            icon={<SearchIcon />}
            selected={matchPath(RegExp(/\/$/))}
            target='/'
            external={false}
          />
          <MenuItem
            title='Documentation'
            icon={<LiveHelpIcon />}
            selected={false}
            target={process.env.REACT_APP_DOCS_LINK || '#'}
            external
          />
          <MenuItem
            title='News'
            icon={<NoteIcon />}
            selected={false}
            target={process.env.REACT_APP_NEWS_LINK || '#'}
            external
          />
          {isAdmin && (
            <>
              <MenuItem
                title='Upload'
                icon={<CloudUploadIcon />}
                selected={matchPath(RegExp(/\/upload$/))}
                target='/upload'
                external={false}
              />
              <LinkMenuItem
                title='Download access report'
                icon={<RecentActorsIcon />}
                target='api/admin/events/'
              />
            </>
          )}
        </List>
      </Drawer>
    </MuiThemeProvider>
  );
};

export default withRouter(Sidebar);
