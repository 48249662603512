import * as t from 'io-ts';

export const UserCodec = t.type({
  firstName: t.string,
  lastName: t.string,
  roles: t.array(t.string)
});

type User = t.TypeOf<typeof UserCodec>;

export default User;
