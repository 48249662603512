import { IO } from 'fp-ts/lib/IO';
import * as IOE from 'fp-ts/lib/IOEither';
import { pipe } from 'fp-ts/lib/pipeable';
import * as TE from 'fp-ts/lib/TaskEither';

/*
    Converts an IO to a TaskEither: useful for `TE.chain` operations in a pipe/sequence
 */
const ioToTe = <T, E>(x: IO<T>): TE.TaskEither<E, T> => pipe(
  x,
  IOE.rightIO,
  TE.fromIOEither
);

export default ioToTe;
