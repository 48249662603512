import { Tooltip, IconButton, styled, CircularProgress } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { IO } from 'fp-ts/lib/IO';
import React from 'react';
import { Status } from '../../store/models/State';

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  // To account for the padding around the icon
  marginRight: -12,
  '&:hover': {
    color: theme.palette.primary.main,
    transition: '0.3s'
  }
}));

interface SearchActionsProps {
    exportResults: IO<void>
    exportStatus: Status
}

const SearchActions: React.FC<SearchActionsProps> = ({ exportResults, exportStatus }) => (
  <Tooltip title='Export'>
    <StyledIconButton
      aria-label='export'
      onClick={exportStatus !== Status.Loading ? exportResults : () => null}
      data-testid='export-button'
    >
      {exportStatus === Status.Loading
        ? <CircularProgress size='large' />
        : <DescriptionIcon fontSize='large' />}
    </StyledIconButton>
  </Tooltip>
);

export default SearchActions;
