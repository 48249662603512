import React from 'react';
import { Switch, Route } from 'react-router';
import Search from '../Search';
import Upload from '../Upload';

const Routes: React.FC = () => (
  <Switch>
    <Route exact path='/upload' component={Upload} />
    <Route path='/' component={Search} />
  </Switch>
);

export default Routes;
