import { Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';

const FixtureAppLogo: React.FC = () => (
  <Box display='flex' flexDirection='row' mx={1}>
    <Typography variant='h5' color='textPrimary'>Marketplace</Typography>
    <Typography variant='h5' color='primary'>App</Typography>
  </Box>
);

export default FixtureAppLogo;
