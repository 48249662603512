/* eslint-disable camelcase */
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import React from 'react';
import { SparePart } from '../../store/models/SpareParts';

interface PriceTableProps {
  sparePart: SparePart
}

const PriceTable: React.FC<PriceTableProps> = ({ sparePart }) => {
  const {
    price_stock_1,
    price_stock_2,
    price_stock_3,
    price_stock_size_1,
    price_stock_size_2,
    price_stock_size_3,
    currency,
  } = sparePart;

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Stock size 1</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Stock size 2</TableCell>
          <TableCell>Price</TableCell>
          <TableCell>Stock size 3</TableCell>
          <TableCell>Price</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            {price_stock_size_1}
          </TableCell>
          <TableCell>
            {`${price_stock_1} ${currency}`}
          </TableCell>
          <TableCell>
            {price_stock_size_2}
          </TableCell>
          <TableCell>
            {`${price_stock_2} ${currency}`}
          </TableCell>
          <TableCell>
            {price_stock_size_3}
          </TableCell>
          <TableCell>
            {`${price_stock_3} ${currency}`}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default PriceTable;
