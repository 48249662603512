import axios from 'axios';
import { pipe } from 'fp-ts/lib/pipeable';
import * as TE from 'fp-ts/lib/TaskEither';

import downloadBlob from '../../utils/downloadBlob';
import getError from '../../utils/getError';
import { filterNullProperties } from '../../utils/nullishHelpers';
import { PageCodec } from '../models/Page';
import { SparePartCodec } from '../models/SpareParts';
import { QueryOptions } from '../reducers/spareparts';

class SparePartsRepository {
    private codec = PageCodec(SparePartCodec);

    find = ({ query, page, pageSize, sortDirection, sortOrder, categories }: QueryOptions) => pipe(
      TE.tryCatch<any, unknown>(
        () => axios.get(
          // eslint-disable-next-line max-len
          `/api/searchengine/spare-parts/search/${query}?pageNumber=${page}&pageSize=${pageSize}&sort.direction=${sortDirection}&sort.order=${sortOrder}&sub_categories=${categories}`
        )
          .then(
            x => x.data,
            err => { throw err }
          ),
        getError
      ),
      TE.map(x => filterNullProperties(x as object)),
      TE.chain(x => TE.fromEither(this.codec.decode(x))),
      TE.mapLeft(err => getError(err))
    );

    get = (id: string) => pipe(
      TE.tryCatch<any, unknown>(
        () => axios.get(`/api/searchengine/spare-parts/${id}`)
          .then(
            x => x.data,
            err => { throw err }
          ),
        getError
      ),
      TE.map(x => filterNullProperties(x as object)),
      TE.chain(x => TE.fromEither(SparePartCodec.decode(x))),
      TE.mapLeft(err => getError(err))
    );

    export = (query: string, selectedCategories: string) => pipe(
      TE.tryCatch(
        () => axios.get(
          `/api/searchengine/spare-parts-export/download/${query}?sub_categories=${selectedCategories || ''}`,
          { responseType: 'blob' }
        ),
        getError
      ),
      TE.map(downloadBlob),
      TE.mapLeft(err => getError(err))
    );
}

export default SparePartsRepository;
