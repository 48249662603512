import { createSlice, PayloadAction, SliceCaseReducers, Dispatch } from '@reduxjs/toolkit';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import * as TE from 'fp-ts/lib/TaskEither';
import debounce from 'lodash.debounce';

import Page from '../models/Page';
import { SparePart } from '../models/SpareParts';
import State, { Status } from '../models/State';
import SparePartsRepository from '../repositories/spareparts';
import { AppThunk } from '../store';

const SparePartsApi = new SparePartsRepository();

type SearchState = State<Page<SparePart>, string>;
type DetailState = State<SparePart, string>;
type ExportState = State<void, string>;

interface SparePartsState {
    search: SearchState,
    detail: DetailState
    export: ExportState
}

const initialState: SparePartsState = {
  search: { status: Status.NotAsked },
  detail: { status: Status.NotAsked },
  export: { status: Status.NotAsked }
};

export const slice = createSlice<SparePartsState, SliceCaseReducers<SparePartsState>>({
  name: 'spareParts',
  initialState,
  reducers: {
    _setSearchLoading: state => (state.search.status === Status.Loading ? state : {
      ...state,
      search: {
        status: Status.Loading,
        data: state.search.status === Status.Success
          ? O.some(state.search.data)
          : O.none
      }
    }),
    _setSearchError: (state, action: PayloadAction<string>) => ({
      ...state,
      search: {
        status: Status.Error,
        error: action.payload } }),
    _setSearchData: (state, action: PayloadAction<Page<SparePart>>) => ({
      ...state,
      search: {
        status: Status.Success,
        data: action.payload
      }
    }),
    _setDetailLoading: state => ({
      ...state,
      detail: {
        status: Status.Loading,
        data: state.detail.status === Status.Success
          ? O.some(state.detail.data)
          : O.none
      }
    }),
    _setDetailError: (state, action: PayloadAction<string>) => ({
      ...state,
      detail: {
        status: Status.Error,
        error: action.payload } }),
    _setDetailData: (state, action: PayloadAction<SparePart>) => ({
      ...state,
      detail: {
        status: Status.Success,
        data: action.payload
      }
    }),
    _resetDetail: state => ({
      ...state,
      detail: {
        status: Status.NotAsked,
      }
    }),
    _setExportLoading: state => ({
      ...state,
      export: {
        status: Status.Loading,
        data: O.none
      }
    }),
    _setExportError: (state, action: PayloadAction<string>) => ({
      ...state,
      export: {
        status: Status.Error,
        error: action.payload } }),
    _resetExport: state => ({
      ...state,
      export: {
        status: Status.NotAsked,
      }
    })
  }
});

export interface QueryOptions {
  query: string
  page: number
  pageSize: number
  sortDirection: 'ASC' | 'DESC'
  sortOrder: 'externalId' | 'description' | 'dimension' | 'weight'
  categories: string
}

export const findSpareParts = (options: QueryOptions) => (dispatch: Dispatch) => {
  const { _setSearchLoading, _setSearchError, _setSearchData } = slice.actions;
  dispatch(_setSearchLoading(null));
  return debounce(() => pipe(
    SparePartsApi.find(options),
    TE.bimap(
      err => dispatch(_setSearchError(err)),
      x => dispatch(_setSearchData(x))
    )
  )(), 1000);
};

export const getSparePart = (id: string): AppThunk => dispatch => {
  const { _setDetailLoading, _setDetailError, _setDetailData } = slice.actions;
  dispatch(_setDetailLoading(null));
  pipe(
    SparePartsApi.get(id),
    TE.bimap(
      err => dispatch(_setDetailError(err)),
      x => dispatch(_setDetailData(x))
    )
  )();
};

export const resetSparePart = (): AppThunk => dispatch => {
  const { _resetDetail } = slice.actions;
  dispatch(_resetDetail(null));
};

export const exportSpareParts = (query: string, selectedCategories: string) => (dispatch: Dispatch) => {
  const { _setExportLoading, _setExportError, _resetExport } = slice.actions;
  dispatch(_setExportLoading(null));
  return pipe(
    SparePartsApi.export(query, selectedCategories),
    TE.bimap(
      err => dispatch(_setExportError(err)),
      x => dispatch(_resetExport(x))
    )
  )();
};

export default slice.reducer;
